import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { defaultFont, textColor } from "../../Styles/styles";
import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
import LinkText from "./LinkText";
import SynonymsNumber from "./SynonymsNumber";
import { theme } from "../../../App";
import { Motion } from "../Motion";

function parseDictionaryData(word) {
  console.log(word);
  if (!word || !word?.meanings?.length > 0) return;
  let meanings = [];
  for (let i = 0; i < word.meanings.length; i++) {
    let finalData = {};
    // Phonetics
    if (word?.phonetics && word?.phonetics[i]) {
      let phonetics = word?.phonetics[i];
      if (phonetics?.text) {
        finalData.pText = phonetics.text;
      }
      if (phonetics?.audio) {
        finalData.pAudio = phonetics.audio;
      }
    }
    if (word?.meanings && word.meanings[i]) {
      finalData.meanings = word.meanings[i];
    }
    meanings.push(finalData);
  }
  return meanings;
}

function BaseData({ examples, count }) {
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));

  if (!examples || !examples?.length > 0) {
    return <></>;
  }

  let fDefinitions = [];
  let fExamples = [];

  for (let i = 0; i < examples.length; i++) {
    if (fExamples.length < 3) {
      if (examples[i].example) {
        fExamples.push(examples[i].example);
      }
    }
    if (
      fDefinitions.join("; ").length < (smallDisplay ? 140 : 280) ||
      fDefinitions.length < 2
    ) {
      if (examples[i].definition) {
        fDefinitions.push(examples[i].definition);
      }
    }
  }

  return (
    <Box sx={{ mb: "0px" }}>
      {fDefinitions?.length > 0 && (
        <>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "15px",
              color: textColor,
              mt: "12px",
            }}
          >
            Definition
          </Typography>
          <Box sx={{ pl: "0px" }}>
            <Typography
              sx={{
                fontWeight: "normal",
                fontStyle: "italic",
                fontSize: "15px",
                color: textColor,
                mt: "16px",
              }}
            >
              "{<LinkText text={fDefinitions.join("; ")} />}"
            </Typography>
          </Box>
        </>
      )}
      {fExamples?.length > 0 && (
        <>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "15px",
              color: textColor,
              mt: "12px",
            }}
          >
            Examples
          </Typography>
          <Box component={"ul"} sx={{ pl: "18px", mb: "4px" }}>
            {fExamples.map((text, i) => (
              <React.Fragment key={count + "LiExamples" + i}>
                {text ? (
                  <Typography
                    key={count + "LiExamplesTypo" + i}
                    component={"li"}
                    sx={{
                      fontWeight: "normal",
                      fontStyle: "italic",
                      fontSize: "15px",
                      color: textColor,
                      mt: "16px",
                      ml: "8px",
                      pl: "12px",
                    }}
                  >
                    "{<LinkText text={text} />}"
                  </Typography>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
}

function PartOfSpeech({ dictData, word }) {
  let list = [];
  dictData.map((data, i) => (
    <Box key={"Word" + i}>
      {parseDictionaryData(data).map((def, j) => {
        list.push({
          part: def.meanings.partOfSpeech,
          box: (
            <Box key={j + "Meaning" + i} sx={{ mt: "36px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"h3"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    color: textColor,
                  }}
                >
                  {def.meanings.partOfSpeech}
                </Typography>
                {def.pText && (
                  <Typography
                    sx={{
                      fontWeight: "normal",
                      fontSize: "15px",
                      color: textColor,
                      ml: "12px",
                    }}
                  >
                    {def.pText}
                  </Typography>
                )}
                {def.pAudio && (
                  <VolumeUpRoundedIcon
                    sx={{
                      color: "black",
                      width: "24px",
                      height: "24px",
                      p: "4px",
                      ml: "6px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const audio = new Audio(def.pAudio);
                      audio.play();
                    }}
                  />
                )}
              </Box>
              <BaseData examples={def.meanings.definitions} count={j} />
            </Box>
          ),
        });
      })}
    </Box>
  ));

  list.sort((a, b) => {
    if (a.part < b.part) return -1;
    if (a.part > b.part) return 1;
    return 0;
  });

  return <>{list.map((data) => data.box)}</>;
}

export default function DefinitionsLayout({
  word,
  dictData,
  allowSynonyms = true,
}) {
  const hasMeanings = dictData && dictData.length > 0;

  const isPhrase = Boolean(String(word).includes(" "));

  if (!hasMeanings) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            color: textColor,
          }}
        >
          {isPhrase ? "Phrase" : "Word"}
        </Typography>
        <Motion>
          <Box sx={{ width: "fit-content" }}>
            <Typography
              component="h1"
              variant="h1"
              style={{
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "42px",
                width: "fit-content",
                color: textColor,
              }}
            >
              {word}
            </Typography>
          </Box>
        </Motion>
        {allowSynonyms && <SynonymsNumber word={word} />}
        <Typography
          sx={{
            fontWeight: "normal",
            fontSize: "15px",
            color: textColor,
            mt: "6px",
          }}
        >
          No definition was found for this {isPhrase ? "phrase" : "word"}.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "16px",
          color: textColor,
        }}
      >
        {isPhrase ? "Phrase" : "Word"}
      </Typography>
      <Motion>
        <Box sx={{ width: "fit-content", mb: "12px" }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              fontWeight: "bold",
              fontSize: "40px",
              lineHeight: "42px",
              width: "fit-content",

              color: textColor,
            }}
          >
            {word}
          </Typography>
        </Box>
      </Motion>
      <SynonymsNumber word={word} />
      {hasMeanings && <PartOfSpeech dictData={dictData} word={word} />}
    </Box>
  );
}
